import React from "react";
import {
  FaHeart,
  FaPaperPlane,
  FaSearch,
  FaTag,
  FaFolder,
  FaBars,
} from "react-icons/fa";
import ArrowRight from "../assets/icons/arrowrightcircle.svg"
import ArrowRightGrey from "../assets/icons/arrowrightcirclegrey.svg";
import Cart from "../assets/icons/cart.png";
import Chart from "../assets/icons/chart.png";
import Google from "../assets/icons/google.svg";
import Salt from "../assets/icons/salt.png";
import Tiktok from "../assets/icons/tiktok.svg";
import X from "../assets/icons/x.png";
import Youtube from "../assets/icons/youtube.svg";
import menu from "../assets/icons/menu.svg";
import drive from "../assets/icons/drive.png";
import instagram from "../assets/icons/instagram.png";
import notes from "../assets/icons/notes.png";
import chrome from "../assets/icons/chrome.png";
import camera from "../assets/icons/camera.svg";
import upload from "../assets/icons/upload.svg";

export interface IconProps {
  type: iconTypes;
}

export type iconTypes =
  | "heart"
  | "paperplane"
  | "search"
  | "tag"
  | "folder"
  | "search"
  | "arrowright"
  | "arrowrightgrey"
  | "bars"
  | "youtube"
  | "tiktok"
  | "salt"
  | "cart"
  | "chart"
  | "google"
  | "x"
  | "hamburger"
  | "drive"
  | "instagram"
  | "notes"
  | "chrome"
  | "camera"
  | "upload"
  | string;

export const Icon: React.FC<IconProps> = ({ type }) => {
  switch (type) {
    case "heart":
      return <FaHeart />;
    case "paperplane":
      return <FaPaperPlane />;
    case "search":
      return <FaSearch />;
    case "tag":
      return <FaTag />;
    case "folder":
      return <FaFolder />;
    case "arrowright":
      return <img src={ArrowRight} alt="arrow right" />;
    case "bars":
      return <FaBars />;
    case "hamburger":
      return <img src={menu} />;
    case "arrowrightgrey":
      return <img src={ArrowRightGrey} alt="arrow right grey" />;
    case "youtube":
      return <img src={Youtube} alt="youtube" />;
    case "tiktok":
      return <img src={Tiktok} alt="tiktok" />;
    case "salt":
      return <img src={Salt} alt="salt" />;
    case "cart":
      return <img src={Cart} alt="cart" />;
    case "chart":
      return <img src={Chart} alt="chart" />;
    case "google":
      return <img src={Google} alt="google" />;
    case "x":
      return <img src={X} alt="x" />;
    case "salt":
      return <img src={Salt} alt="salt" />;
    case "instagram":
      return <img src={instagram} alt="salt" />;
    case "notes":
      return <img src={notes} alt="notes" />;
    case "chrome":
      return <img src={chrome} alt="chrome" />;
    case "upload":
      return <img src={upload} alt="upload" />;
    case "camera":
      return <img src={camera} alt="camera" />;
    case "drive":
      return <img src={drive} alt="salt" />;
    default:
      return null; // or provide a default icon if needed
  }
};
