import { Home } from "./screens/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import WebFont from "webfontloader";
import "./index.css";
import { Nav } from "./components/Nav";
import { Support } from "./screens/Support";
import { Footer } from "./components/Footer";
import { SharedRecipe } from "./screens/SharedRecipe";

function App() {

  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/support" element={<Support />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
