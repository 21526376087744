import styled from "styled-components";
import { iconTypes } from "../Icon";
import { colors } from "../../constants/colors";
import { StyledText } from "../../constants/text";

export interface TestimonyCardProps {
  text: string;
  username: string;
  subname: string;
  src: string;
}

export const TestimonyCard: React.FC<TestimonyCardProps> = ({
  text,
  username,
  subname,
  src,
}) => {
  return (
    <TestimonyCardMasterContainter>
      <TestimonyContainer>
        <StyledText.Subtitle_2>{text}</StyledText.Subtitle_2>
      </TestimonyContainer>

      <StyledLine />
      <CreatorBottomContent>
        <img src={src} style={{ width: 44, height: 44 }} />
        <TestimonyNameStack>
          <StyledText.Subtitle_3>{username}</StyledText.Subtitle_3>
          <StyledText.Subtitle_3 color={colors.neutral_7}>
            {subname}
          </StyledText.Subtitle_3>
        </TestimonyNameStack>
      </CreatorBottomContent>
    </TestimonyCardMasterContainter>
  );
};

const TestimonyCardMasterContainter = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 236px;
  border-color: ${colors.neutral_3};
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
`;

const TestimonyContainer = styled.div`
  display: flex;
  padding-left: 28px;
  padding-right: 28px;
  height: 156px;
  justify-content: center;
  align-items: center;
`;

const TestimonyNameStack = styled.div`
  flex-direction: column;
  display: flex;
`;

const CreatorBottomContent = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 24px;
  height: 80px;
`;

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.neutral_2};
`;
