import styled from "styled-components";
import { Nav } from "../components/Nav";
import { Survey } from "../components/Survey";
import { StyledText } from "../constants/text";
import { colors } from "../constants/colors";
import Nick from "../assets/images/creator_profiles/nickhomewebsite.png";
import { SurveyButton } from "../components/molecules/SurveyButton";
import { CostRowPanel } from "../components/organisms/CostRowPanel";
import { CarouselHandler } from "../components/organisms/CarouselHandler";
import { SurveyButton2 } from "../components/molecules/SurveyButton2";
import { AnimatedHero } from "../components/organisms/AnimatedHero";
import apple from "../assets/images/appstore.svg";
import google from "../assets/images/playstore.png";
import { Padding } from "../components/atoms/Padding";
import { TestimonyCard } from "../components/molecules/TestimonyCard";
import ben from "../assets/images/ben.png";
import jessie from "../assets/images/jessie.png";
import jen from "../assets/images/jen.png";
import bottom_sheet_mock from "../assets/images/phones/bottomsheet_mock.png";
import folder_mock from "../assets/images/phones/folder_mock.png";
import detail_mock from "../assets/images/phones/detail_mock.png";
import home_mock from "../assets/images/phones/home_mock.png";
import { PrimaryButton } from "../components/atoms/PrimaryButton";
import { Footer } from "../components/Footer";

export const Home = () => {
  return (
    <StyledHomePageContainer>
      <StyledMainContainer>
        <StyledHeroContainer>
          <ResponsiveHeaderText>
            All your recipes in one place
          </ResponsiveHeaderText>
          <StyledText.Subtitle_1 style={{ textAlign: "center", maxWidth: 505 }}>
            Organize your recipes and share with loved ones. Create your digital
            recipe box on salt for free.
          </StyledText.Subtitle_1>
        </StyledHeroContainer>
        <Padding height={"53px"} />
        <HeroContainer>
          <AnimatedHero />
        </HeroContainer>
        <h1 style={{ textAlign: "center", paddingRight: 26, paddingLeft: 26 }}>
          <StyledText.Display_3 color={colors.primary_1}>
            Salt{" "}
          </StyledText.Display_3>
          <StyledText.Display_3>
            recipe app is now available
          </StyledText.Display_3>
        </h1>

        <StyledText.Subtitle_1
          color={colors.neutral_7}
          style={{ textAlign: "center", paddingRight: 26, paddingLeft: 26 }}
        >
          Download on App Store now. Coming on Google Play soon.
        </StyledText.Subtitle_1>
        <Padding height={"26px"} />
        <AppStoreContainer>
          <img src={apple} style={{ width: 192, height: "auto" }} />
          <img src={google} style={{ width: 192, height: "auto" }} />
        </AppStoreContainer>
        <Padding height={"103px"} />

        <TestimonyContainer>
          <TestimonyCard
            src={jessie}
            text={
              "“I used to have all of my favorite recipes saved as URLs in a document but I quickly imported them all to salt and it has been a life saver!!”"
            }
            username={"Jessie"}
            subname={"College student"}
          />
          <TestimonyCard
            src={ben}
            text={
              "“I had all of my recipes saved on social media and on print outs, now they are all together in one location. Love this app!”"
            }
            username={"Ben Chelin"}
            subname={"@recipesincaption"}
          />
          <TestimonyCard
            src={jen}
            text={
              "“My recipe management used to be a mess. Thanks to Salt I can now search all of my recipes with ease.”"
            }
            username={"Jen"}
            subname={"Homecook, mom of 3"}
          />
        </TestimonyContainer>
        <Padding height={"143px"} />
        <StyledText.Display_3
          style={{
            textAlign: "center",
            maxWidth: 685,
            marginLeft: 24,
            marginRight: 24,
          }}
        >
          Everything you've ever wanted to organize your recipes
        </StyledText.Display_3>
        <Padding height={"142px"} />

        <StyledPhoneContainer>
          <TranslatingPhone src={bottom_sheet_mock} style={{ width: 284 }} />
        </StyledPhoneContainer>
        <PhoneSubtextContainer>
          <StyledText.Display_4>
            Smart import your recipes from anywhere
          </StyledText.Display_4>
          <StyledText.Subtitle_2 color={colors.neutral_7}>
            No more clutter, just the recipe.
          </StyledText.Subtitle_2>
        </PhoneSubtextContainer>
        <Padding height={"152px"} />
        <StyledPhoneContainer>
          <TranslatingPhone src={folder_mock} style={{ width: 284 }} />
        </StyledPhoneContainer>
        <PhoneSubtextContainer>
          <StyledText.Display_4>
            Use folders to organize all your recipes
          </StyledText.Display_4>
          <StyledText.Subtitle_2 color={colors.neutral_7}>
            Manage your recipes using folders.
          </StyledText.Subtitle_2>
        </PhoneSubtextContainer>
        <Padding height={"152px"} />
        <StyledPhoneContainer>
          <TranslatingPhone src={detail_mock} style={{ width: 284 }} />
        </StyledPhoneContainer>
        <PhoneSubtextContainer>
          <ResponsiveDisplay4>
            Share your favorite recipes with friends and family
          </ResponsiveDisplay4>
          <StyledText.Subtitle_2 color={colors.neutral_7}>
            Send your favorite recipes to loved ones.
          </StyledText.Subtitle_2>
        </PhoneSubtextContainer>
        <Padding height={"152px"} />
        <StyledPhoneContainerOverflow>
          <img src={home_mock} style={{ width: 447 }} />
        </StyledPhoneContainerOverflow>
        <Padding height={"36px"} />
        <StyledText.Display_4 style={{ textAlign: "center", maxWidth: 410 }}>
          Welcome to your new digital recipe box
        </StyledText.Display_4>
        <Padding height={"20px"} />

        <StyledText.Subtitle_3 color={colors.neutral_7}>
          Available now on IOS. Download for free!
        </StyledText.Subtitle_3>
        <Padding height={"24px"} />

        <PrimaryButton text={"Give salt a try"} onPress={() => {}} />
      </StyledMainContainer>
      <Padding height={"72px"} />
    </StyledHomePageContainer>
  );
};
const StyledHomePageContainer = styled.div`
  position: relative;
`;
const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  @media screen and (min-width: 744px) {
    margin-top: 82px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 93px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 80px;
  }
  @media screen and (min-width: 1920px) {
    margin-top: 125px;
  }
  transition: margin-top 0.5s ease;
`;

const PhoneSubtextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 26px;
  max-width: 547px;
  margin-left: 24px;
  margin-right: 24px;
  gap: 16px;
`;

const StyledHeroContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const StyledPhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: ${colors.neutral_3};
  border-width: 1px;
  border-style: solid;
  @media screen and (min-width: 547px) {
    width: 547px;
    height: 700px;
  }
  width: 380px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  height: 486px;
  border-radius: 26px;
`;

const TranslatingPhone = styled.img`
  @media screen and (min-width: 547px) {
    transform: translateY(0px);
  }
  transition: transform 0.3s ease-in-out;

  transform: translateY(70px);
`;

const StyledPhoneContainerOverflow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-color: ${colors.neutral_3};
  border-width: 1px;
  border-style: solid;
  padding-top: 48px;
  border-radius: 26px;
  transition: width 0.3s ease-in-out;

  @media screen and (min-width: 547px) {
    width: 547px;
  }
  width: 380px;
`;

const PhoneSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
`;
const TestimonyContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row; /* Default to row */
  @media screen and (max-width: 1180px) {
    flex-direction: column; /* Change to column for smaller screens */
  }
`;
const AppStoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 28px;
`;

const ResponsiveDisplay4 = styled(StyledText.Display_4)`
  font-size: 40px;

  text-align: left;
  transition: font-size 0.3s ease-in-out; /* Smooth transition for font-size */

  @media screen and (max-width: 428px) {
    font-size: 28px; /* Smaller font size for small screens */
  }
`;

const HeroContainer = styled.div`
  padding-left: 100px;
`;
const StyledTextHero = styled(StyledText.Title_2)`
  text-align: center;

  @media screen and (min-width: 744px) {
    text-align: left;
  }
  @media screen and (min-width: 834px) {
    font-size: 28px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 48px;
  }
  transition: font-size 0.5s ease;
  margin-top: 0;
  margin-bottom: 0;
`;

const StyledTextSubHero = styled(StyledText.Subtitle_3)`
  text-align: center;
  margin-top: 16px;
  @media screen and (min-width: 744px) {
    text-align: left;
  }
  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 24px;
  }
  transition: all 0.5s ease;
  margin-top: 16;
  margin-bottom: 0;
`;

const StyledTextHeroContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 279px;
  @media screen and (min-width: 834px) {
    width: 327px;
  }
  @media screen and (min-width: 1024px) {
    width: 456px;
  }
  @media screen and (min-width: 1440px) {
    width: 583px;
  }
  transition: all 0.5s ease;
`;

const ResponsiveHeaderText = styled(StyledText.Display_1)`
  font-size: clamp(40px, 8vw, 64px); /* Scales from 40px to 64px */
  text-align: center;
`;

const StyledPhoneImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledPhoneImageContainer = styled.div`
  width: 250px;
  height: auto;
  order: -1;
  @media screen and (min-width: 744px) {
    order: 0;
  }
  @media screen and (min-width: 1024 px) {
    width: 304px;
  }

  transition: width 0.5s ease;
`;

const StyledTextHeroContainerWithSurvey = styled.div`
  display: flex;
  flex-direction: column;
  gap: 41px;
  align-items: center;
  @media screen and (min-width: 744px) {
    align-items: flex-start;
  }
`;

const StyledTextHero2 = styled(StyledText.Title_2)`
  width: 287px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  @media screen and (min-width: 744px) {
    font-size: 48px;
    width: 584px;
  }
  @media screen and (min-width: 1024px) {
    width: 670px;
  }
  transition: all 0.5s ease;
`;

const StyledCostRowPanelBackground = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
  justify-content: center;
  margin-left: 80px;
  margin-right: 80px;

  @media screen and (min-width: 834px) {
    gap: 28px;
  }
  @media screen and (min-width: 1024px) {
    background-color: #f5f5f7;
    border-radius: 28px;
    border-width: 1px;
    border-style: solid;
    border-color: #dbdbdb;
    padding-bottom: 90px;
    padding-top: 58px;
    padding-left: 97px;
    padding-right: 97px;
    gap: 64px;
  }
  @media screen and (min-width: 1440px) {
    gap: 43px;
  }
  transition: all 0.5s ease;
`;

const StyledHeading = styled(StyledText.Title_2)`
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (min-width: 1024px) {
    font-size: 64px;
  }
  transition: font-size 0.5s ease;
`;
const StyledSubheading = styled(StyledText.Subtitle_3)`
  margin-top: 0px;
  margin-bottom: 0px;
  @media screen and (min-width: 1024px) {
    font-size: 32px;
    font-weight: 500;
  }
  transition: all 0.5s ease;
`;
const StyledHeadingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 48px;
`;

const StyledCreatorHeadingMasterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledLine = styled.div`
  border-top-width: 1px;
  border-top-style: solid;
  border-color: ${colors.neutral_3};
  width: 90%;

  margin-top: 48px;
  margin-bottom: 50px;

  @media screen and (min-width: 744px) {
    margin-top: 101px;
    margin-bottom: 48px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 109px;
    margin-bottom: 102px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 125px;
    margin-bottom: 95px;
  }
  @media screen and (min-width: 1920px) {
    margin-top: 99px;
    margin-bottom: 99px;
  }

  transition: all 0.5s ease;
`;

const StyledSurveyButton2Container = styled.div`
  margin-top: 44px;
`;
