import styled, { keyframes } from "styled-components";
import HeroImage from "../../assets/images/HeroImage.png";
import { StyledText } from "../../constants/text";
import { AnimatedHeroLabel } from "../molecules/AnimatedHeroLabel";
import { colors } from "../../constants/colors";
import { Icon } from "../Icon";
import NonnaPasta from "../../assets/images/nonna_pasta.png";
import NonnaIcon from "../../assets/images/nonna_icon.png";

export const AnimatedHero: React.FC = () => {
  return (
    <StyledHeroMaster
      style={{
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <StyledHeroImage src={HeroImage} />

      <StyledLabelBox style={{ left: 200 }}>
        <StyledHeroLabel
          animatedDelay="0s"
          style={{
            paddingLeft: 120,
            paddingRight: 36,
            flexShrink: 0,
            whiteSpace: "nowrap",
          }}
        >
          <Icon type={"drive"} />
          <StyledText.Title_2>
            No more docs full of recipe links
          </StyledText.Title_2>
        </StyledHeroLabel>

        <StyledHeroLabel
          animatedDelay="0.3s"
          style={{ paddingLeft: 180, paddingRight: 36 }}
        >
          <Icon type={"instagram"} />
          <StyledText.Title_2 style={{ width: 236 }}>
            Import saved recipes from social media
          </StyledText.Title_2>
        </StyledHeroLabel>

        <StyledHeroLabel animatedDelay="0.6s" style={{ paddingLeft: 200 }}>
          <Icon type={"notes"} />
          <StyledText.Title_2>Import from notes app easily</StyledText.Title_2>
        </StyledHeroLabel>

        <StyledHeroLabel
          animatedDelay="0.6s"
          style={{ paddingLeft: 220, paddingRight: 36 }}
        >
          <Icon type={"upload"} />
          <StyledText.Title_2>
            Share recipes with friends and family
          </StyledText.Title_2>
        </StyledHeroLabel>
      </StyledLabelBox>

      <StyledLabelBox style={{ right: 250, top: 40, alignItems: "flex-end" }}>
        <StyledHeroLabel
          animatedDelay="0.9s"
          style={{ paddingLeft: 32, paddingRight: 120 }}
        >
          <Icon type={"chrome"} />
          <StyledText.Title_2 style={{ minWidth: 400 }}>
            Import any recipe URL
          </StyledText.Title_2>
        </StyledHeroLabel>

        <StyledHeroLabel
          animatedDelay="1.2s"
          style={{ paddingLeft: 32, paddingRight: 215 }}
        >
          <StyledNonnaContainer>
            <img src={NonnaPasta} style={{ width: "100%", height: "100%" }} />
            <StyledMiniNonna src={NonnaIcon} />
          </StyledNonnaContainer>
          <StyledNonnaText>
            <StyledText.Subtitle_2 color={colors.neutral_7}>
              Organize with folders
            </StyledText.Subtitle_2>
            <StyledText.Title_2 style={{ width: 236 }}>
              Grandma Nonna's Legacy Recipes
            </StyledText.Title_2>
            <StyledText.Subtitle_2 color={colors.neutral_7}>
              Folder from Nonna
            </StyledText.Subtitle_2>
          </StyledNonnaText>
        </StyledHeroLabel>

        <StyledHeroLabel
          animatedDelay="1.5s"
          style={{ paddingLeft: 32, paddingRight: 320 }}
        >
          <Icon type={"camera"} />
          <StyledText.Title_2 style={{ maxWidth: 236 }}>
            Scan cookbooks and recipe cards
          </StyledText.Title_2>
        </StyledHeroLabel>
      </StyledLabelBox>
    </StyledHeroMaster>
  );
};

const slideIn = keyframes`
  from {
    transform: translateX(-150%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

const StyledHeroLabel = styled.div<{ animatedDelay: string }>`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 24px;
  border-radius: 12px;
  align-items: center;
  border: 1px solid ${colors.neutral_3};
  background: white;
  max-width: fit-content;
  /* Animation styles */
  animation: ${slideIn} 0.8s ease-out forwards;
  animation-delay: ${(props) => props.animatedDelay};
  transform: translateX(-150%);
  opacity: 0;
`;

const StyledNonnaContainer = styled.div`
  width: 176px;
  height: 176px;
  position: relative;
`;

const StyledMiniNonna = styled.img`
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 4;
`;

const StyledNonnaText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledLabelBox = styled.div`
  top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 26px;
  position: absolute;
  z-index: -1;
  opacity: 1;
  @media screen and (max-width: 1170px) {
    opacity: 0;
  }
  transition: opacity 0.4s ease-in;
`;

const StyledHeroImage = styled.img`
  width: 527px;
  height: 726px;
  position: relative;
  z-index: 1;
`;

const StyledHeroMaster = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
