import styled from "styled-components";
import { StyledText } from "../../constants/text";
import { colors } from "../../constants/colors";

export interface PrimaryButtonProps {
  onPress: () => void;
  text: string;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  onPress,
  text,
}) => {
  return (
    <StyledButtonContainer>
      <StyledText.Subtitle_4 color={colors.shade_1}>
        {text}
      </StyledText.Subtitle_4>
    </StyledButtonContainer>
  );
};

const StyledButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 18px;
  align-items: center;
  height: 31px;
  background-color: ${colors.primary_1};
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: scale(1.05); /* Slightly enlarges the button */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Adds a subtle shadow */
  }
  flex-shrink: 0;
`;
