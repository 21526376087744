import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledText } from "../constants/text";
import { colors } from "../constants/colors";
import { Icon } from "./Icon";
import { PrimaryButton } from "./atoms/PrimaryButton";

export const Nav = () => {
  return (
    <StyledNavContainer>
      <StyledLeftContainer>
        <StyledLogo color={colors.primary_1}>salt</StyledLogo>
      </StyledLeftContainer>
      <StyledMiddleNavContainer>
        <StyledNavLink to={'/'}>About</StyledNavLink>
        <StyledNavLink to={'/support'}>Support</StyledNavLink>
      </StyledMiddleNavContainer>
      <StyledRightContainer>
        <PrimaryButton text={"Get the app"} onPress={() => {}} />
        <StyledMenu>
          <Icon type="hamburger" />
        </StyledMenu>
      </StyledRightContainer>
    </StyledNavContainer>
  );
};

const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 1920px) {
    gap: 120px;
  }
  @media screen and (max-width: 1024px) {
    gap: 65px;
  }
  @media screen and (max-width: 834px) {
    gap: 61px;
  }
  @media screen and (max-width: 430px) {
  }
  transition: gap 0.5s ease;
`;

const StyledNavLink = styled(Link)`
  font-family: Satoshi;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  color: ${colors.shade_2}; /* Adjust color as needed */
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.primary_1}; /* Change color on hover */
  }

  /* Underline animation */
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: ${colors.primary_1};
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%; /* Full width underline on hover */
  }
`;

const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 23px;
`;

const StyledMiddleNavContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 65px;
  justify-content: space-between;
  @media screen and (max-width: 744px) {
    display: none; /* Remove from layout flow on smaller screens */
  }
`;

const StyledRightTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 38px;
  position: relative;
  opacity: 1;
  @media screen and (max-width: 743px) {
    opacity: 0;
  }
`;

const StyledNavContainer = styled.nav`
  background-color: #fff;
  position: sticky;
  z-index: 10;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 21px;
  padding-right: 0px;
  padding-top: 32px;
  padding-bottom: 16px;
  box-shadow: none; /* Default: No shadow */

  @media screen and (max-width: 744px) {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding-right: 24px;
  }

  @media screen and (min-width: 744px) {
    padding-left: 80px;
  }

  @media screen and (min-width: 1440px) {
    padding-left: 127px;
  }

  transition: padding-left 0.5s ease, box-shadow 0.3s ease;
`;

const StyledMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  @media screen and (min-width: 744px) {
    opacity: 0;
  }
`;

const StyledLogo = styled(StyledText.Logo_1)`
  font-size: 32px;
  @media screen and (min-width: 744px) {
    font-size: 48px;
  }
  transition: font-size 0.5s ease;
`;
