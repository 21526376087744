import styled from "styled-components";
import { StyledText } from "../constants/text";
import { colors } from "../constants/colors";
import { Padding } from "../components/atoms/Padding";

export const Support: React.FC = () => {
  return (
    <StyledMainContainer>
      <StyledText.Display_1>Contact us</StyledText.Display_1>
      <StyledText.Body_1 style={{ maxWidth: 480, textAlign: "center" }}>
        We'd love to hear from you! Whether you have questions, feedback, or
        just want to say hello.
      </StyledText.Body_1>
      <Padding height={"64px"}/>
      <StyledFormContainer>
        <StyledSingleInputContainer>
          <StyledText.Body_1>Your name</StyledText.Body_1>
          <StyledInput placeholder="Your name" />
        </StyledSingleInputContainer>

        <StyledSingleInputContainer>
          <StyledText.Body_1>Your email</StyledText.Body_1>

          <StyledInput placeholder="Your email" />
        </StyledSingleInputContainer>
        <StyledSingleInputContainer>
          <StyledText.Body_1>How can we help you?</StyledText.Body_1>

          <StyledTextArea placeholder="Your message" />
        </StyledSingleInputContainer>
      </StyledFormContainer>
    </StyledMainContainer>
  );
};

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  @media screen and (min-width: 744px) {
    margin-top: 82px;
  }
  @media screen and (min-width: 1024px) {
    margin-top: 93px;
  }
  @media screen and (min-width: 1440px) {
    margin-top: 80px;
  }
  @media screen and (min-width: 1920px) {
    margin-top: 125px;
  }
  transition: margin-top 0.5s ease;
`;

const StyledInput = styled.input`
  width: 480px;
  padding: 12px 16px;
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.shade_2};
  border: 1px solid ${colors.neutral_2};
  border-radius: 8px;
  outline: none;
  transition: all 0.3s ease;

  /* Placeholder Styling */
  ::placeholder {
    color: ${colors.neutral_6};
    font-family: Satoshi;
    font-size: 18px;
    font-weight: 500;
  }

  /* Hover State */
  &:hover {
    border-color: ${colors.primary_1};
  }

  /* Focus State */
  &:focus {
    border-color: ${colors.primary_1};
    box-shadow: 0 0 5px rgba(0, 122, 255, 0.3);
  }

  /* Disabled State */
  &:disabled {
    background-color: ${colors.neutral_3};
    cursor: not-allowed;
  }
`;

const StyledTextArea = styled.textarea`
  width: 480px;
  height: 158px; /* Larger height for the paragraph field */
  padding: 12px 16px;
  font-family: Satoshi;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.shade_2};
  border: 1px solid ${colors.neutral_2};
  border-radius: 8px;
  outline: none;
  transition: all 0.3s ease;
  resize: none; /* Allow the user to resize the textarea vertically */
  /* Placeholder Styling */
  ::placeholder {
    color: ${colors.neutral_6};
    font-family: Satoshi;
    font-size: 18px;
    font-weight: 500;
  }

  /* Hover State */
  &:hover {
    border-color: ${colors.primary_1};
  }

  /* Focus State */
  &:focus {
    border-color: ${colors.primary_1};
    box-shadow: 0 0 5px rgba(0, 122, 255, 0.3);
  }

  /* Disabled State */
  &:disabled {
    background-color: ${colors.neutral_3};
    cursor: not-allowed;
  }
`;

const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;
`;

const StyledSingleInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
