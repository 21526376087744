import styled from "styled-components";
import { colors } from "../constants/colors";
import { StyledText } from "../constants/text";

export const Footer: React.FC = () => {
  return (
    <StyledFooterContainer>
      <a>
        <StyledText.Subtitle_2 color={colors.neutral_7}>
          Contact us
        </StyledText.Subtitle_2>
      </a>
      <a>
        <StyledText.Subtitle_2 color={colors.neutral_7}>
          Privacy Policy
        </StyledText.Subtitle_2>
      </a>

      <a>
        <StyledText.Subtitle_2 color={colors.neutral_7}>
          Terms of Service
        </StyledText.Subtitle_2>
      </a>
    </StyledFooterContainer>
  );
};

const StyledFooterContainer = styled.div`
  height: 191px;
  background-color: ${colors.neutral_1};
  gap: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
